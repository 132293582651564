import React from "react";
import { graphql } from "gatsby";
import Layout from "src/components/structure/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";

const PeelTemplate = ({ data }) => {
  const peel = data.mdx;
  return (
    <Layout pageTitle={peel.frontmatter.title}>
      <div
        style={{
          backgroundColor: "black",
          isolation: "isolate",
          marginBottom: "2rem",
          position: "relative",
        }}
      >
        <div
          className="container"
          style={{ paddingTop: "4rem", paddingBottom: "4rem" }}
        >
          <h1
            style={{
              backgroundColor: "var(--primary)",
              borderTop: "4px solid white",
              borderBottom: "8px solid white",
              color: "white",
              padding: "1rem 2rem",
            }}
          >
            {peel.frontmatter.title}
          </h1>
        </div>
        <GatsbyImage
          alt=""
          image={getImage(
            peel.frontmatter.image.childImageSharp.gatsbyImageData
          )}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0.8,
            zIndex: -1,
          }}
        />
      </div>
      <div
        className="container"
        style={{
          marginBottom: "4rem",
          paddingLeft: "3rem",
          paddingRight: "3rem",
        }}
      >
        <MDXRenderer>{peel.body}</MDXRenderer>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
        link
        date
        dateLabel
        image {
          childImageSharp {
            gatsbyImageData(width: 400, layout: CONSTRAINED)
          }
        }
        teaser
        cta
      }
    }
  }
`;

export default PeelTemplate;
